<template>
  <div>
    <Drawer
      display-btn
      :style="transform()"
      :visible="visible"
      :is-mask="true"
      size="small"
      :z-index="3"
      @close="close"
    >
      <div slot="title" class="tip_title">
        <span>审批进度</span>
        <div class="approve_drawer_title" :style="color">{{ applyStatusName }}</div>
      </div>
      <div>
        <a-button class="details_button" prefix="left" @click="getFlowRecordDetail">申请详情</a-button>
        <!-- <div class="details_button" @click="getFlowRecordDetail">
          <a-icon type="left" />
          <span class="detail">申请详情</span>
        </div> -->
        <a-steps direction="vertical" :current="2">
          <a-step v-for="(item, index) in stepsList" :key="index">
            <!-- 展示的icon -->
            <div slot="icon" class="approve_icon">
              <div
                v-if="
                  ['process_start', 'approval_transfer', 'approval_transfer_add', 'carbon_copy_add']
                    .includes(item.operateType)
                "
                style="display: flex;justify-content: center;align-items: center;width: 100%;height: 100%"
              >
                <CBAvator
                  :on-job-id="item.operatorId"
                  :avater-name="item.operatorName"
                  size="40"
                  :is-person-info="false"
                  :show-user-name="false"
                />
              </div>
              <!-- 通知抄送人 -->
              <div v-else-if="['carbon_copy_notify'].includes(item.operateType)">
                <svg class="iconpark-icon"><use href="#mingxiweihuyichang1" /></svg>
              </div>
              <!-- 自动通过 -->
              <div v-else-if="['approval_auto_accept'].includes(item.operateType)" class="approve_icon_name">
                <div v-if="item.operatorId">
                  <CBAvator
                    :on-job-id="item.operatorId"
                    :avater-name="item.operatorName"
                    size="40"
                    :show-user-name="false"
                  />
                </div>
                <svg v-else class="iconpark-icon"><use href="#chaosong" /></svg>
              </div>
              <div v-else>
                <svg class="iconpark-icon"><use href="#audit-5o5mjp9j" /></svg>
              </div>
              <!-- 定位的小图标，头像右下角那个icon -->
              <div
                v-if="item.operateType !== 'error_waiting'"
                class="approve_icon_status"
                :class="item.operateType === 'approval_waiting' ?
                  'approve_icon_status_waiting' :
                  item.operateType === 'approval_reject' ?
                    'approve_icon_status_error' :
                    ''"
              >
                <svg
                  v-if="
                    ['approval_accept'
                     , 'process_start'
                     , 'approval_auto_accept'
                     , 'approval_transfer_add'
                     , 'carbon_copy_notify'
                     , 'approval_transfer'].includes(item.operateType) "
                  class="iconpark-icon approve_icon_status_color"
                >
                  <use href="#check" />
                </svg>
                <svg v-if="item.operateType === 'approval_reject'" class="iconpark-icon approve_icon_status_color"><use href="#close-small" /></svg>
                <span v-if="['approval_waiting'].includes(item.operateType)" class="approve_icon_status_color">···</span>
                <!-- <a-icon
                  v-if="
                    ['approval_accept'
                     , 'process_start'
                     , 'approval_auto_accept'
                     , 'approval_transfer_add'
                     , 'carbon_copy_notify'
                     , 'approval_transfer'].includes(item.operateType) "
                  type="check"
                  class="approve_icon_status_color"
                />
                <a-icon v-if="item.operateType === 'approval_reject'" type="close" class="approve_icon_status_color" />
                <a-icon v-if="['approval_waiting'].includes(item.operateType)" type="ellipsis" class="approve_icon_status_color" /> -->
              </div>
            </div>
            <!-- 标题部分 -->
            <div slot="title" class="approve_title">
              <div class="approve_title_name">
                <div>
                  <span class="approve_title_name_count">
                    <span>{{ stepItemTitleName(item.operatorName, item.operateType, item.carbonCopyUsers ? item.carbonCopyUsers.length : 0) }}</span>
                    <span v-if="item.operatorSource == 'admin'">(管理员)</span>
                  </span>
                  <span v-if="item.operateType === 'approval_waiting'" class="approve_title_name_public sign">
                    <span v-if="item.approvalType!==2" class="approve_title_name_public sign">({{ approvalTypeList[item.approvalType] }})</span>
                    <span v-if="item.approvalType===2 && item.approvals.length > 1" class="approve_title_name_public sign">(会签)</span>
                  </span>
                </div>
                <div>
                  <span class="approve_title_name_public">{{ item.operateTime }}</span>
                </div>
              </div>
              <div class="approve_title_status">
                <div v-if="item.operateType === 'process_start'" class="approve_title_status_finished">发起审批</div>
                <div
                  v-if="
                    ['approval_accept']
                      .includes(item.operateType)"
                  class="approve_title_status_finished"
                >已通过</div>
                <div
                  v-if="
                    ['approval_transfer', 'approval_transfer_add']
                      .includes(item.operateType)"
                  class="approve_title_status_finished"
                >添加了转交</div>
                <div v-if="item.operateType === 'approval_reject'" class="approve_title_status_error">未通过</div>
                <div v-if="item.operateType === 'approval_waiting'" class="approve_title_status_progress">审核中</div>
                <div v-if="item.operateType === 'carbon_copy_add'" class="approve_title_status_add_cc">添加了抄送人</div>
                <div v-if="item.operateType === 'approval_auto_accept'" class="approve_title_status_add_cc">自动通过</div>
                <div v-if="item.operateType === 'error_waiting'" class="approve_title_status_progress">审批人为空，等待HR干预</div>
              </div>
            </div>
            <div slot="description" class="approve_descriptions">
              <!-- 展示签名 -->
              <img v-if="item.signFileId" :src="item.imgPath" style="height: 60px" @click="signPreview(item.imgPath)">
              <!-- 审批通过的情况下展示审批人，并且展示审批意见 -->
              <div v-if="item.opinion" class="opinion">
                审批意见：{{ item.opinion }}
              </div>
              <!--      转交        -->
              <div v-if="item.operateType === 'approval_transfer' || item.operateType === 'approval_transfer_add'" class="approve_descriptions_transfer">
                <!-- 判断如果是hr操作 取操作人 -->
                <template v-if="item.operatorSource == 'admin'">
                  <template v-if="item.sourceUserName">
                    {{ item.operatorName }}将审批人从{{ item.sourceUserName }}转交为{{ item.targetUserName }}
                  </template>
                  <template v-else>
                    {{ item.operatorName }}将审批人转交给{{ item.targetUserName }}
                  </template>
                </template>
                <template v-else>
                  转交给{{ item.targetUserName }}
                </template>
              </div>
              <!--     原因(例如离职原因)      -->
              <div
                v-if="['approval_auto_accept',
                       'process_cancel',
                       'process_error_stop',
                       'approval_transfer_add',
                       'carbon_copy_add'].includes(item.operateType)"
                class="approve_descriptions_reason"
              >
                {{ item.reason }}
              </div>
              <!--     审批列表      -->
              <div
                v-if="item.operateType === 'approval_waiting' ||
                  item.operateType === 'approval_accept' ||
                  item.operateType === 'carbon_copy_add' ||
                  item.operateType === 'carbon_copy_notify'"
                class="approve_descriptions_people_list"
              >
                <div v-for="it in item.approvals" :key="it.userId" class="approve_descriptions_public">
                  <CBAvator
                    :on-job-id="it.userId"
                    :avater-name="it.userName"
                    size="26"
                  />
                </div>
              </div>
              <!--     抄送人        -->
              <div v-if="item.operateType === 'carbon_copy_add' || item.operateType === 'carbon_copy_notify'" class="approve_cc">
                <div class="approve_cc_icon" @click="getFlagChange(index)">
                  <a-icon v-if="item.flag" type="caret-up" />
                  <a-icon v-else type="caret-down" />
                  <span class="approve_cc_icon_num">抄送{{ item.carbonCopyUsers.length }}人</span>
                  <span class="approve_cc_icon_status">已抄送</span>
                </div>
                <div v-if="item.flag">
                  <div v-for="it in item.carbonCopyUsers" :key="it.userId" class="approve_descriptions_people_list">
                    <CBAvator
                      :on-job-id="it.userId"
                      :avater-name="it.userName"
                      size="26"
                    />
                  </div>
                </div>
              </div>
            </div>
          </a-step>
        </a-steps>
        <slot name="approveButton" :participant="participant" />
      </div>
      <Drawer
        :closable="false"
        :is-mask="true"
        :visible="childrenDrawer"
        size="small"
        display-btn
        @onSubmit="childrenDrawer = false"
        @close="childrenDrawerClose"
      >
        <div slot="title" class="detail_title" @click="childrenDrawer=false">
          <!-- <a-icon type="left" /> -->
          <a-button type="iconBtn" icon="left" />
          <span>申请详情</span>
        </div>
        <div>
          <!--    信息卡片      -->
          <div class="childrenDrawer_card">
            <CBAvator
              :on-job-id="flowRecord.initiatorId"
              :avater-name="flowRecord.initiatorName"
              size="40"
            />
            <div class="childrenDrawer_card_org">{{ flowRecord.initiatorOrgName }}</div>
          </div>
          <!-- 详情中展示的表单 -->
          <template v-for="(key, formItemIndex) in formList">
            <!-- 非明细类的展示样式 -->
            <template v-if="!key.list">
              <!-- 常规的展示 -->
              <template v-if="typeof key.value !== 'object'">
                <div :key="`formItemIndex${formItemIndex}`" class="childrenDrawer_quit">
                  <div class="detailTitle">{{ key.name }}</div>
                  <div class="childrenDrawer_quit_data">{{ key.value }}</div>
                </div>
              </template>
              <!-- 图片附件的展示 -->
              <template v-else>
                <div :key="`formItemIndex${formItemIndex}`" class="childrenDrawer_quit">
                  <div class="detailTitle">{{ key.name }}</div>
                  <div class="List">
                    <div v-for="(item, index) in key.value" :key="`item.value${index}`" class="childrenDrawer_quit_data imgItems annex">
                      <!-- <template v-if="item.type === 'image'">
                        <img :src="item.url" alt="" srcset="">
                      </template> -->
                      <template>
                        <div>
                          <img :src="iconList[item.label.split('.')[1]].img" alt="" class="iconList">
                          <span style="cursor: pointer;" class="download_text">{{ item.label }}</span>
                        </div>
                        <div>
                          <a-button v-if="item.type==='image'" class="show" type="link" @click="viewThePicture(item)">预览</a-button>
                          <a v-else :href="item.url" style="cursor: pointer;" class="show" download>下载</a>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </template>
            </template>
            <!-- 明细类的展示样式 -->
            <template v-else class="index">
              <div :key="`formItemIndex${formItemIndex}`" class="detailTitle">{{ key.title }}</div>
              <template v-for="(k, i) in key.list">
                <div :key="`detail${i}`" class="detail">
                  <div class="detailItemTile">明细{{ i + 1 }}</div>
                  <div v-for="(j, t) in k" :key="`detailItemTileContent${t}`" class="detailItemTileContent">
                    <!-- 常规的展示 -->
                    <template v-if="typeof j.value !== 'object'">
                      <div :key="`formItemIndex${t}`" class="childrenDrawer_quit">
                        <div>{{ j.name }}</div>
                        <div class="childrenDrawer_quit_data">{{ j.value }}</div>
                      </div>
                    </template>
                    <!-- 图片附件的展示 -->
                    <template v-if="typeof j.value === 'object'">
                      <div :key="`formItemIndex${t}`" class="childrenDrawer_quit">
                        <div>{{ j.name }}</div>
                        <div class="List">
                          <div v-for="(item, index) in j.value" :key="`item.value${index}`" class="childrenDrawer_quit_data annex">
                            <!-- <template v-if="item.type === 'image'">
                              <img :src="item.url" alt="" srcset="">
                            </template> -->
                            <template>
                              <div>
                                <img :src="iconList[item.label.split('.')[1]].img" alt="" class="iconList">
                                <span style="cursor: pointer;" class="download_text">{{ item.label }}</span>
                              </div>
                              <div>
                                <a-button v-if="item.type==='image'" class="show" type="link" @click="viewThePicture(item)">预览</a-button>
                                <a v-else :href="item.url" style="cursor: pointer;" class="show" download>下载</a>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </template>
              <div :key="`detailBottom${formItemIndex}`" class="detailBottom">
                <div
                  v-for="(k, i) in key.summaryList"
                  :key="`summaryItem${i}`"
                  class="summaryList"
                >
                  <div class="childrenDrawer_quit">
                    <div>{{ k.label }}汇总</div>
                    <div class="childrenDrawer_quit_data">{{ k.value }}</div>
                  </div>
                </div>
              </div>
            </template>
          </template>
        </div>
      </Drawer>
    </Drawer>
    <Modal :visible="previewVisible" closable overflow-auto :footer="null" :z-index="99999" :title="`查看大图`" :size="'normal'" :mask-closable="false" @cancel="previewVisible=false">
      <div class="model"><img alt="example" style="max-width: 100%" :src="previewImageId"></div>
    </Modal>
  </div>
</template>

<script>
import { getFlowRecordOperateList, getFlowRecordDetail } from '@/services/approveSteps/approveSteps.js'
import { getRecordFormInfo } from '@/services/home/process.js'
import { getSignatures, getSignature } from '@/services/upload/index.js'
import { formateData } from './formateFormData'
import Drawer from '@/components/CbDrawer/index'
export default {
  name: 'CbApproveSteps',
  components: {
    Modal: () => import('@/components/CbModal/index'),
    CBAvator: () => import('@/components/CbAvater/theAvater'),
    Drawer
  },
  model: {
    prop: 'visibleShow',
    event: 'drawerVisible'
  },
  props: {
    visibleShow: {
      type: Boolean,
      default: false
    },
    flowId: {
      type: String,
      default: ''
    },
    applyStatus: {
      type: [String, Number],
      default: ''
    },
    applyStatusList: {
      type: Object,
      default: () => {}
    },
    isHome: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      previewImageId: null,
      previewVisible: false,
      basePath: process.env.VUE_APP_API_BASE_URL,
      visible: false,
      childrenDrawer: false,
      stepsList: [],
      flowRecord: {},
      formList: [],
      approvalTypeList: [
        '依次审批',
        '或签',
        '会签',
        ''
      ],
      participant: [],
      iconList: {
        docx: { img: require('@/assets/img/Word.png') },
        pdf: { img: require('@/assets/img/PDF.png') },
        xlsx: { img: require('@/assets/img/xlsx.png') },
        png: { img: require('@/assets/img/PNG.png') },
        jpg: { img: require('@/assets/img/JPG.png') },
        jepg: { img: require('@/assets/img/JEPG.png') }
      },
      colorList: {
        color: '',
        bgColor: '',
        bdColor: ''
      },
      statusList: [
        {
          // 审批中
          key: 1,
          status: 'inapprove',
          color: 'rgba(237, 123, 47, 1)',
          bgColor: 'rgba(237, 123, 47, .1)'
        },
        {
          // 已通过
          key: 2,
          status: 'completeapprove',
          color: 'rgba(0, 168, 112, 1)',
          bgColor: 'rgba(0, 168, 112, .1)'
        },
        {
          // 未通过
          key: 3,
          status: 'rejectapprove',
          color: 'rgba(227, 77, 89, 1)',
          bgColor: 'rgba(227, 77, 89, .1)'
        },
        {
          // 等待HR干预
          key: 4,
          status: 'exception',
          color: 'rgba(237, 123, 47, 1)',
          bgColor: 'rgba(237, 123, 47, .1)'
        },
        {
          // 审批终止
          key: 5,
          status: 'exceptionend',
          color: 'rgba(227, 77, 89, 1)',
          bgColor: 'rgba(227, 77, 89, .1)'
        },
        {
          // 撤销审批
          key: 6,
          status: ' revokeapprove',
          color: 'rgba(143, 147, 161, 0.6)',
          bgColor: 'rgba(143, 147, 161, 0.1)'
        }
      ]
    }
  },
  computed: {
    color() {
      if (!this.applyStatus) return
      const { color, bgColor, bdColor } = this.colorList
      return `color: ${color}; background: ${bgColor}; border: 1px solid ${bdColor}`
    },
    applyStatusName() {
      if (!this.applyStatus) return
      return this.applyStatusList[this.applyStatus].name
    }
  },
  watch: {
    visibleShow(val) {
      this.visible = val
      if (val) {
        this.getFlowRecordOperateList()
        this.isHome ? this.getFlowRecordDetail('true') : ''
      }
    },
    applyStatus(val) {
      this.statusList.map(item => {
        if (Number(val) === item.key || val === item.status) {
          this.colorList.color = item.color
          this.colorList.bgColor = item.bgColor
          this.colorList.bdColor = item.color
        }
      })
    }
  },
  methods: {
    async getSignPath(signFileId) {
      const signatureRes = await getSignature({
        id: signFileId
      })
      const imgPath = await `${this.basePath}/hrsaas-storage/image/thumbnail?id=${signFileId}&signature=${signatureRes.data.signature}&expireTime=${signatureRes.data.time}`
      return imgPath
    },
    async viewThePicture(item) {
      const signature = await getSignatures({ ids: item.value })
      this.previewImageId = `${this.basePath}/hrsaas-storage/image/thumbnail/name?id=${item.value}&width=${750}&expireTime=${signature.data[0].time}&signature=${signature.data[0].signature}`
      this.previewVisible = true
    },
    signPreview(imgPath) {
      this.previewImageId = imgPath
      this.previewVisible = true
    },
    transform() {
      if (this.childrenDrawer) {
        return `transform: translateX(-400px);`
      }
    },
    async getFlowRecordOperateList() {
      const res = await getFlowRecordOperateList({
        flowRecordId: this.flowId
      })
      this.stepsList = []
      for (let i = 0; i < res.data.length; i++) {
        const item = res.data[i]
        let imgPath = null
        if (item.signFileId) {
          imgPath = await this.getSignPath(item.signFileId)
        }
        this.stepsList.push({
          ...item,
          // 是否展开全部抄送人
          imgPath,
          flag: false
        })
      }
      this.stepsList = this.stepsList.filter(item => item.operateType !== 'process_complete')
    },
    getFlagChange(index) {
      this.stepsList[index].flag = !this.stepsList[index].flag
    },
    close() {
      this.visible = false
      this.$emit('drawerVisible', this.visible)
    },
    async getFlowRecordDetail(isFirst) {
      const api = this.isHome && isFirst === 'true' ? getRecordFormInfo : getFlowRecordDetail
      const res = await api({
        flowRecordId: this.flowId
      })
      if (this.isHome && isFirst === 'true') {
        const { flowSetting, participant } = res.data
        this.participant = { ...participant, ...flowSetting }
      } else {
        const { flowRecord, formData, formDesign } = res.data
        const { list } = formDesign
        this.flowRecord = flowRecord
        const formList = []
        await formateData(formData, list, false, formList, this.basePath)
        this.formList = formList
        this.childrenDrawer = true
      }
      this.$forceUpdate()
    },
    childrenDrawerClose() {
      this.childrenDrawer = false
    },
    stepItemTitleName(operatorName, operateType, perNum) {
      const typeList = [
        {
          name: operatorName,
          list: ['process_start', 'approval_transfer', 'approval_transfer_add', 'approval_accept', 'approval_reject', 'approval_auto_accept']
        },
        {
          name: '审批',
          list: ['approval_waiting']
        },
        {
          name: `已抄送${perNum}人`,
          list: ['carbon_copy_notify']
        },
        {
          name: '抄送',
          list: ['carbon_copy_add']
        },
        {
          name: '', // 审批异常终止，没有标题
          list: ['process_error_stop']
        }
      ]
      for (let i = 0; i < typeList.length; i++) {
        const item = typeList[i]
        if (item.list.includes(operateType)) {
          return item.name
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
  /deep/.ant-steps-item-content{
    min-height: 120px;
  }
  /deep/ .ant-steps-vertical {
    margin-left: 5px;
    .ant-steps-item-icon {
      margin-right: 22px;
    }
  }
  /deep/.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail{
    padding: 45px 0 10px 0;
    &::after{
      width: 2PX;
    }
  }
  /deep/.img_box{
    background: none!important;
  }
  .opinion{
    margin-top: 15px;
  }
  .tip_title {
    display: flex;
    align-items: center;
    color: @sc-grey-100;
    font-size: 16px;
    line-height: 24px;
    .approve_drawer_title {
      padding: 1px 10px;
      height: 26px;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      text-align: center;
      line-height: 23px;
      margin-left: 20px;
      border: 0 !important;
    }
  }
  .details_button {
    color: @sc-primary-100;
    border-color: @sc-primary-100;
    margin-bottom: 24px;
    &:hover {
      background: @sc-primary-10;
    }
  }
  .approve_icon {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: @sc-primary-100;
    margin-left: -5px;
    text-align: center;
    line-height: 40px;
    .approve_icon_name {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: #ffffff;
    }
    .approve_icon_name_icon {
      color: #FFFFFF;
      font-size: 20px;
      display: block;
      line-height: 44px;
      margin-left: 2px;
    }
    .approve_icon_status {
      position: absolute;
      right: -3px;
      bottom: 0px;
      width: 16px;
      height: 16px;
      background: @sc-success-100;
      text-align: center;
      line-height: 6px;
      border-radius: 50%;
    }
    .approve_icon_status_error {
      background: @sc-error-100;
    }
    .approve_icon_status_waiting {
      background: @sc-warning-100;
    }
    span.approve_icon_status_color {
      text-align: center;
      display: inline-block;
      margin: 0 !important;
      width: auto !important;
      height: auto !important;
      vertical-align: middle;
      font-size: 16px !important;
    }
    .approve_icon_status_color {
      width: 12px;
      height: 12px;
      font-size: 12px;
      color: #FFFFFF;
      margin: 2px 0 0 2px;
    }
  }
  .approve_title {
    .approve_title_name {
      display: flex;
      justify-content: space-between;
      width: 360px;
      margin-top: -8px;
      .approve_title_name_count {
        font-size: 14px;
        color: @sc-grey-100;
      }
      .approve_title_name_public {
        font-size: 14px;
        line-height: 22px;
        margin-right: 0;
        color: @sc-grey-60;
      }
      .sign {
        font-size: 12px;
        margin-left: 8px;
      }
    }
    .approve_title_status {
      font-size: 14px;
      margin-top: -10px;
      .approve_title_status_finished {
        color: @sc-success-100;
      }
      .approve_title_status_error {
        color: @sc-error-100;
      }
      .approve_title_status_progress{
        color: @sc-warning-100;
      }
      .approve_title_status_add_cc {
        font-size: 14px;
        color: @sc-grey-60;
      }
    }
  }
  .approve_descriptions {
    margin: 10px 0 5px;
    .approve_descriptions_transfer {
      font-size: 14px;
      color: @sc-grey-100;
      margin: 15px 0 0 0;
      .approve_descriptions_transfer_name {
        color: @sc-primary-100;
        margin: 5px;
      }
    }
    .approve_descriptions_reason {
      width: 360px;
      font-size: 14px;
      color: @sc-grey-100;
      line-height: 22px;
      margin: 14px 0 0 0;
    }
    .approve_descriptions_people_list {
      display: flex;
      flex-wrap: wrap;
    }
    .approve_cc {
      margin: 10px 0 0 0;
      .approve_cc_icon {
        font-size: 14px;
        color: rgba(143, 147, 161, 0.6);
        cursor: pointer;
        .approve_cc_icon_num {
          margin-left: 4px;
          color: @sc-grey-60;
        }
        .approve_cc_icon_status{
          color: @sc-grey-100;
          margin-left: 10px;
        }
      }
    }
    .approve_descriptions_public {
      margin-right: 10px;
    }
  }
  .detail_title{
    .iconBtn{
      width: 20px;
      height: 20px;
      padding: 1px;
      margin-right: 5px;
    }
  }
  .childrenDrawer_card {
    display: flex;
    align-items: center;
    width: 100%;
    height: 56px;
    background: @sc-greyBg-10;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    line-height: 22px;
    color: @sc-grey-100;
    margin-bottom: 16px;
    /deep/.box{
      margin-left: 12px;
      .avater-name{
        font-size: 14px;
      }
      .user-name{
        margin-left: 11px;
      }
    }
    .childrenDrawer_card_org {
      margin-left: 60px;
    }
  }
  .childrenDrawer_quit {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 22px;
    color: @sc-grey-60;
    .List {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      .iconList {
        width: 20px;
        height: 22px;
        line-height: 18px;
        margin-right: 6px;
        img {
          width: 100%;
        }
      }
    }
    .childrenDrawer_quit_data {
      margin-top: 16px;
      font-size: 14px;
      color:@sc-grey-100;
      .icon-container {
        display: flex;
        align-items: center;
      }
      .iconList {
        width: 20px;
        height: 22px;
        line-height: 18px;
        margin-right: 6px;
        img {
          width: 100%;
        }
      }
      a {
        color: @sc-primary-100;
      }
    }
    .childrenDrawer_upload {
      display: flex;
      flex-wrap: wrap;
      .childrenDrawer_upload_file {
        min-width: 20px;
        font-size: 14px;
        color: @sc-primary-100;
        margin-top: 8px;
        cursor: pointer;
        margin-right: 37px;
      }
    }
  }
  .detailTitle {
    font-size: 14px;
    font-weight: 600;
    padding: 12px 0;
  }
  .detailBottom {
    margin-top: 16px;
  }
  /deep/ .name {
    line-height: 22px;
  }
  /deep/ .ant-steps-item-process .ant-steps-item-title {
    font-weight: normal;
  }
  /deep/ .iconpark-icon {
    width: 25px;
    height: 25px;
    color: #fff;
    margin-top: 6px;
    margin-left: 2px;
  }
  .annex{
    width: 100%;
    height: 34px;
    display: flex;
    padding: 0 12px;
    border: 1px solid @sc-grey-20;
    border-radius: 5px ;
    align-items: center;
    justify-content: space-between;
    .show{
      display: none;
    }
    &:hover{
      background: @sc-greyBg-10;
      border-color:@sc-greyBg-10 ;
      .show{
        display: block;
      }
    }
  }
.detail{
  padding: 6px 12px;
  border: 1px solid @sc-grey-20;
}
/deep/.modal-content{
  display: flex;
  justify-content: center;
}
.model{
  overflow: auto;
}
</style>
