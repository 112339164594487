var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Drawer",
        {
          style: _vm.transform(),
          attrs: {
            "display-btn": "",
            visible: _vm.visible,
            "is-mask": true,
            size: "small",
            "z-index": 3,
          },
          on: { close: _vm.close },
        },
        [
          _c(
            "div",
            {
              staticClass: "tip_title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c("span", [_vm._v("审批进度")]),
              _c(
                "div",
                { staticClass: "approve_drawer_title", style: _vm.color },
                [_vm._v(_vm._s(_vm.applyStatusName))]
              ),
            ]
          ),
          _c(
            "div",
            [
              _c(
                "a-button",
                {
                  staticClass: "details_button",
                  attrs: { prefix: "left" },
                  on: { click: _vm.getFlowRecordDetail },
                },
                [_vm._v("申请详情")]
              ),
              _c(
                "a-steps",
                { attrs: { direction: "vertical", current: 2 } },
                _vm._l(_vm.stepsList, function (item, index) {
                  return _c("a-step", { key: index }, [
                    _c(
                      "div",
                      {
                        staticClass: "approve_icon",
                        attrs: { slot: "icon" },
                        slot: "icon",
                      },
                      [
                        [
                          "process_start",
                          "approval_transfer",
                          "approval_transfer_add",
                          "carbon_copy_add",
                        ].includes(item.operateType)
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "justify-content": "center",
                                  "align-items": "center",
                                  width: "100%",
                                  height: "100%",
                                },
                              },
                              [
                                _c("CBAvator", {
                                  attrs: {
                                    "on-job-id": item.operatorId,
                                    "avater-name": item.operatorName,
                                    size: "40",
                                    "is-person-info": false,
                                    "show-user-name": false,
                                  },
                                }),
                              ],
                              1
                            )
                          : ["carbon_copy_notify"].includes(item.operateType)
                          ? _c("div", [
                              _c("svg", { staticClass: "iconpark-icon" }, [
                                _c("use", {
                                  attrs: { href: "#mingxiweihuyichang1" },
                                }),
                              ]),
                            ])
                          : ["approval_auto_accept"].includes(item.operateType)
                          ? _c("div", { staticClass: "approve_icon_name" }, [
                              item.operatorId
                                ? _c(
                                    "div",
                                    [
                                      _c("CBAvator", {
                                        attrs: {
                                          "on-job-id": item.operatorId,
                                          "avater-name": item.operatorName,
                                          size: "40",
                                          "show-user-name": false,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _c("svg", { staticClass: "iconpark-icon" }, [
                                    _c("use", { attrs: { href: "#chaosong" } }),
                                  ]),
                            ])
                          : _c("div", [
                              _c("svg", { staticClass: "iconpark-icon" }, [
                                _c("use", {
                                  attrs: { href: "#audit-5o5mjp9j" },
                                }),
                              ]),
                            ]),
                        item.operateType !== "error_waiting"
                          ? _c(
                              "div",
                              {
                                staticClass: "approve_icon_status",
                                class:
                                  item.operateType === "approval_waiting"
                                    ? "approve_icon_status_waiting"
                                    : item.operateType === "approval_reject"
                                    ? "approve_icon_status_error"
                                    : "",
                              },
                              [
                                [
                                  "approval_accept",
                                  "process_start",
                                  "approval_auto_accept",
                                  "approval_transfer_add",
                                  "carbon_copy_notify",
                                  "approval_transfer",
                                ].includes(item.operateType)
                                  ? _c(
                                      "svg",
                                      {
                                        staticClass:
                                          "iconpark-icon approve_icon_status_color",
                                      },
                                      [_c("use", { attrs: { href: "#check" } })]
                                    )
                                  : _vm._e(),
                                item.operateType === "approval_reject"
                                  ? _c(
                                      "svg",
                                      {
                                        staticClass:
                                          "iconpark-icon approve_icon_status_color",
                                      },
                                      [
                                        _c("use", {
                                          attrs: { href: "#close-small" },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                ["approval_waiting"].includes(item.operateType)
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "approve_icon_status_color",
                                      },
                                      [_vm._v("···")]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "approve_title",
                        attrs: { slot: "title" },
                        slot: "title",
                      },
                      [
                        _c("div", { staticClass: "approve_title_name" }, [
                          _c("div", [
                            _c(
                              "span",
                              { staticClass: "approve_title_name_count" },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.stepItemTitleName(
                                        item.operatorName,
                                        item.operateType,
                                        item.carbonCopyUsers
                                          ? item.carbonCopyUsers.length
                                          : 0
                                      )
                                    )
                                  ),
                                ]),
                                item.operatorSource == "admin"
                                  ? _c("span", [_vm._v("(管理员)")])
                                  : _vm._e(),
                              ]
                            ),
                            item.operateType === "approval_waiting"
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "approve_title_name_public sign",
                                  },
                                  [
                                    item.approvalType !== 2
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "approve_title_name_public sign",
                                          },
                                          [
                                            _vm._v(
                                              "(" +
                                                _vm._s(
                                                  _vm.approvalTypeList[
                                                    item.approvalType
                                                  ]
                                                ) +
                                                ")"
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    item.approvalType === 2 &&
                                    item.approvals.length > 1
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "approve_title_name_public sign",
                                          },
                                          [_vm._v("(会签)")]
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _c("div", [
                            _c(
                              "span",
                              { staticClass: "approve_title_name_public" },
                              [_vm._v(_vm._s(item.operateTime))]
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "approve_title_status" }, [
                          item.operateType === "process_start"
                            ? _c(
                                "div",
                                {
                                  staticClass: "approve_title_status_finished",
                                },
                                [_vm._v("发起审批")]
                              )
                            : _vm._e(),
                          ["approval_accept"].includes(item.operateType)
                            ? _c(
                                "div",
                                {
                                  staticClass: "approve_title_status_finished",
                                },
                                [_vm._v("已通过")]
                              )
                            : _vm._e(),
                          [
                            "approval_transfer",
                            "approval_transfer_add",
                          ].includes(item.operateType)
                            ? _c(
                                "div",
                                {
                                  staticClass: "approve_title_status_finished",
                                },
                                [_vm._v("添加了转交")]
                              )
                            : _vm._e(),
                          item.operateType === "approval_reject"
                            ? _c(
                                "div",
                                { staticClass: "approve_title_status_error" },
                                [_vm._v("未通过")]
                              )
                            : _vm._e(),
                          item.operateType === "approval_waiting"
                            ? _c(
                                "div",
                                {
                                  staticClass: "approve_title_status_progress",
                                },
                                [_vm._v("审核中")]
                              )
                            : _vm._e(),
                          item.operateType === "carbon_copy_add"
                            ? _c(
                                "div",
                                { staticClass: "approve_title_status_add_cc" },
                                [_vm._v("添加了抄送人")]
                              )
                            : _vm._e(),
                          item.operateType === "approval_auto_accept"
                            ? _c(
                                "div",
                                { staticClass: "approve_title_status_add_cc" },
                                [_vm._v("自动通过")]
                              )
                            : _vm._e(),
                          item.operateType === "error_waiting"
                            ? _c(
                                "div",
                                {
                                  staticClass: "approve_title_status_progress",
                                },
                                [_vm._v("审批人为空，等待HR干预")]
                              )
                            : _vm._e(),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "approve_descriptions",
                        attrs: { slot: "description" },
                        slot: "description",
                      },
                      [
                        item.signFileId
                          ? _c("img", {
                              staticStyle: { height: "60px" },
                              attrs: { src: item.imgPath },
                              on: {
                                click: function ($event) {
                                  return _vm.signPreview(item.imgPath)
                                },
                              },
                            })
                          : _vm._e(),
                        item.opinion
                          ? _c("div", { staticClass: "opinion" }, [
                              _vm._v(
                                " 审批意见：" + _vm._s(item.opinion) + " "
                              ),
                            ])
                          : _vm._e(),
                        item.operateType === "approval_transfer" ||
                        item.operateType === "approval_transfer_add"
                          ? _c(
                              "div",
                              { staticClass: "approve_descriptions_transfer" },
                              [
                                item.operatorSource == "admin"
                                  ? [
                                      item.sourceUserName
                                        ? [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.operatorName) +
                                                "将审批人从" +
                                                _vm._s(item.sourceUserName) +
                                                "转交为" +
                                                _vm._s(item.targetUserName) +
                                                " "
                                            ),
                                          ]
                                        : [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.operatorName) +
                                                "将审批人转交给" +
                                                _vm._s(item.targetUserName) +
                                                " "
                                            ),
                                          ],
                                    ]
                                  : [
                                      _vm._v(
                                        " 转交给" +
                                          _vm._s(item.targetUserName) +
                                          " "
                                      ),
                                    ],
                              ],
                              2
                            )
                          : _vm._e(),
                        [
                          "approval_auto_accept",
                          "process_cancel",
                          "process_error_stop",
                          "approval_transfer_add",
                          "carbon_copy_add",
                        ].includes(item.operateType)
                          ? _c(
                              "div",
                              { staticClass: "approve_descriptions_reason" },
                              [_vm._v(" " + _vm._s(item.reason) + " ")]
                            )
                          : _vm._e(),
                        item.operateType === "approval_waiting" ||
                        item.operateType === "approval_accept" ||
                        item.operateType === "carbon_copy_add" ||
                        item.operateType === "carbon_copy_notify"
                          ? _c(
                              "div",
                              {
                                staticClass: "approve_descriptions_people_list",
                              },
                              _vm._l(item.approvals, function (it) {
                                return _c(
                                  "div",
                                  {
                                    key: it.userId,
                                    staticClass: "approve_descriptions_public",
                                  },
                                  [
                                    _c("CBAvator", {
                                      attrs: {
                                        "on-job-id": it.userId,
                                        "avater-name": it.userName,
                                        size: "26",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                        item.operateType === "carbon_copy_add" ||
                        item.operateType === "carbon_copy_notify"
                          ? _c("div", { staticClass: "approve_cc" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "approve_cc_icon",
                                  on: {
                                    click: function ($event) {
                                      return _vm.getFlagChange(index)
                                    },
                                  },
                                },
                                [
                                  item.flag
                                    ? _c("a-icon", {
                                        attrs: { type: "caret-up" },
                                      })
                                    : _c("a-icon", {
                                        attrs: { type: "caret-down" },
                                      }),
                                  _c(
                                    "span",
                                    { staticClass: "approve_cc_icon_num" },
                                    [
                                      _vm._v(
                                        "抄送" +
                                          _vm._s(item.carbonCopyUsers.length) +
                                          "人"
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "approve_cc_icon_status" },
                                    [_vm._v("已抄送")]
                                  ),
                                ],
                                1
                              ),
                              item.flag
                                ? _c(
                                    "div",
                                    _vm._l(item.carbonCopyUsers, function (it) {
                                      return _c(
                                        "div",
                                        {
                                          key: it.userId,
                                          staticClass:
                                            "approve_descriptions_people_list",
                                        },
                                        [
                                          _c("CBAvator", {
                                            attrs: {
                                              "on-job-id": it.userId,
                                              "avater-name": it.userName,
                                              size: "26",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    }),
                                    0
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ])
                }),
                1
              ),
              _vm._t("approveButton", null, { participant: _vm.participant }),
            ],
            2
          ),
          _c(
            "Drawer",
            {
              attrs: {
                closable: false,
                "is-mask": true,
                visible: _vm.childrenDrawer,
                size: "small",
                "display-btn": "",
              },
              on: {
                onSubmit: function ($event) {
                  _vm.childrenDrawer = false
                },
                close: _vm.childrenDrawerClose,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "detail_title",
                  attrs: { slot: "title" },
                  on: {
                    click: function ($event) {
                      _vm.childrenDrawer = false
                    },
                  },
                  slot: "title",
                },
                [
                  _c("a-button", { attrs: { type: "iconBtn", icon: "left" } }),
                  _c("span", [_vm._v("申请详情")]),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "div",
                    { staticClass: "childrenDrawer_card" },
                    [
                      _c("CBAvator", {
                        attrs: {
                          "on-job-id": _vm.flowRecord.initiatorId,
                          "avater-name": _vm.flowRecord.initiatorName,
                          size: "40",
                        },
                      }),
                      _c("div", { staticClass: "childrenDrawer_card_org" }, [
                        _vm._v(_vm._s(_vm.flowRecord.initiatorOrgName)),
                      ]),
                    ],
                    1
                  ),
                  _vm._l(_vm.formList, function (key, formItemIndex) {
                    return [
                      !key.list
                        ? [
                            typeof key.value !== "object"
                              ? [
                                  _c(
                                    "div",
                                    {
                                      key: "formItemIndex" + formItemIndex,
                                      staticClass: "childrenDrawer_quit",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "detailTitle" },
                                        [_vm._v(_vm._s(key.name))]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "childrenDrawer_quit_data",
                                        },
                                        [_vm._v(_vm._s(key.value))]
                                      ),
                                    ]
                                  ),
                                ]
                              : [
                                  _c(
                                    "div",
                                    {
                                      key: "formItemIndex" + formItemIndex,
                                      staticClass: "childrenDrawer_quit",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "detailTitle" },
                                        [_vm._v(_vm._s(key.name))]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "List" },
                                        _vm._l(
                                          key.value,
                                          function (item, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: "item.value" + index,
                                                staticClass:
                                                  "childrenDrawer_quit_data imgItems annex",
                                              },
                                              [
                                                [
                                                  _c("div", [
                                                    _c("img", {
                                                      staticClass: "iconList",
                                                      attrs: {
                                                        src: _vm.iconList[
                                                          item.label.split(
                                                            "."
                                                          )[1]
                                                        ].img,
                                                        alt: "",
                                                      },
                                                    }),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "download_text",
                                                        staticStyle: {
                                                          cursor: "pointer",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.label)
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                  _c(
                                                    "div",
                                                    [
                                                      item.type === "image"
                                                        ? _c(
                                                            "a-button",
                                                            {
                                                              staticClass:
                                                                "show",
                                                              attrs: {
                                                                type: "link",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.viewThePicture(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("预览")]
                                                          )
                                                        : _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "show",
                                                              staticStyle: {
                                                                cursor:
                                                                  "pointer",
                                                              },
                                                              attrs: {
                                                                href: item.url,
                                                                download: "",
                                                              },
                                                            },
                                                            [_vm._v("下载")]
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                              ],
                                              2
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  ),
                                ],
                          ]
                        : [
                            _c(
                              "div",
                              {
                                key: "formItemIndex" + formItemIndex,
                                staticClass: "detailTitle",
                              },
                              [_vm._v(_vm._s(key.title))]
                            ),
                            _vm._l(key.list, function (k, i) {
                              return [
                                _c(
                                  "div",
                                  { key: "detail" + i, staticClass: "detail" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "detailItemTile" },
                                      [_vm._v("明细" + _vm._s(i + 1))]
                                    ),
                                    _vm._l(k, function (j, t) {
                                      return _c(
                                        "div",
                                        {
                                          key: "detailItemTileContent" + t,
                                          staticClass: "detailItemTileContent",
                                        },
                                        [
                                          typeof j.value !== "object"
                                            ? [
                                                _c(
                                                  "div",
                                                  {
                                                    key: "formItemIndex" + t,
                                                    staticClass:
                                                      "childrenDrawer_quit",
                                                  },
                                                  [
                                                    _c("div", [
                                                      _vm._v(_vm._s(j.name)),
                                                    ]),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "childrenDrawer_quit_data",
                                                      },
                                                      [_vm._v(_vm._s(j.value))]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            : _vm._e(),
                                          typeof j.value === "object"
                                            ? [
                                                _c(
                                                  "div",
                                                  {
                                                    key: "formItemIndex" + t,
                                                    staticClass:
                                                      "childrenDrawer_quit",
                                                  },
                                                  [
                                                    _c("div", [
                                                      _vm._v(_vm._s(j.name)),
                                                    ]),
                                                    _c(
                                                      "div",
                                                      { staticClass: "List" },
                                                      _vm._l(
                                                        j.value,
                                                        function (item, index) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key:
                                                                "item.value" +
                                                                index,
                                                              staticClass:
                                                                "childrenDrawer_quit_data annex",
                                                            },
                                                            [
                                                              [
                                                                _c("div", [
                                                                  _c("img", {
                                                                    staticClass:
                                                                      "iconList",
                                                                    attrs: {
                                                                      src: _vm
                                                                        .iconList[
                                                                        item.label.split(
                                                                          "."
                                                                        )[1]
                                                                      ].img,
                                                                      alt: "",
                                                                    },
                                                                  }),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "download_text",
                                                                      staticStyle:
                                                                        {
                                                                          cursor:
                                                                            "pointer",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.label
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]),
                                                                _c(
                                                                  "div",
                                                                  [
                                                                    item.type ===
                                                                    "image"
                                                                      ? _c(
                                                                          "a-button",
                                                                          {
                                                                            staticClass:
                                                                              "show",
                                                                            attrs:
                                                                              {
                                                                                type: "link",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.viewThePicture(
                                                                                    item
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "预览"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _c(
                                                                          "a",
                                                                          {
                                                                            staticClass:
                                                                              "show",
                                                                            staticStyle:
                                                                              {
                                                                                cursor:
                                                                                  "pointer",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                href: item.url,
                                                                                download:
                                                                                  "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "下载"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                            ],
                                                            2
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      )
                                    }),
                                  ],
                                  2
                                ),
                              ]
                            }),
                            _c(
                              "div",
                              {
                                key: "detailBottom" + formItemIndex,
                                staticClass: "detailBottom",
                              },
                              _vm._l(key.summaryList, function (k, i) {
                                return _c(
                                  "div",
                                  {
                                    key: "summaryItem" + i,
                                    staticClass: "summaryList",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "childrenDrawer_quit" },
                                      [
                                        _c("div", [
                                          _vm._v(_vm._s(k.label) + "汇总"),
                                        ]),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "childrenDrawer_quit_data",
                                          },
                                          [_vm._v(_vm._s(k.value))]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ],
                    ]
                  }),
                ],
                2
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: {
            visible: _vm.previewVisible,
            closable: "",
            "overflow-auto": "",
            footer: null,
            "z-index": 99999,
            title: "查看大图",
            size: "normal",
            "mask-closable": false,
          },
          on: {
            cancel: function ($event) {
              _vm.previewVisible = false
            },
          },
        },
        [
          _c("div", { staticClass: "model" }, [
            _c("img", {
              staticStyle: { "max-width": "100%" },
              attrs: { alt: "example", src: _vm.previewImageId },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }